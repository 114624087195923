import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownItemDto } from '../../service-proxies/service-proxies';

@Component({
  selector: 'abp-pagination-controls',
    templateUrl: './abp-pagination-controls.component.html'
})
export class AbpPaginationControlsComponent {
  @Input() allowPageSizeChange: boolean;
  @Input() id: string;
  @Input() maxSize = 7;
  @Input() previousLabel = 'Previous';
  @Input() nextLabel = 'Next';
  @Input() screenReaderPaginationLabel = 'Pagination';
  @Input() screenReaderPageLabel = 'page';
  @Input() screenReaderCurrentLabel = `You're on page`;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  selectedShowMoreValue: number = 10;
    pageSizeDropdown: DropdownItemDto[] = [
        new DropdownItemDto({ id: 10, name: "10" }),
        new DropdownItemDto({ id: 20, name: "20" }),
        new DropdownItemDto({ id: 50, name: "50" }),
        new DropdownItemDto({ id: 100, name: "100" }),
    ];

  private _directionLinks = true;
  private _autoHide = false;

  @Input()
  get directionLinks(): boolean {
    return this._directionLinks;
  }
  set directionLinks(value: boolean) {
    this._directionLinks = !!value && <any>value !== 'false';
  }
  @Input()
  get autoHide(): boolean {
    return this._autoHide;
  }
  set autoHide(value: boolean) {
    this._autoHide = !!value && <any>value !== 'false';
  }
}
